import React from "react";
import styles from "./BlogGridView.module.scss";
import { BlogList } from "../../../models/blog-list.model";
import BlogCard from "../wrappers/blog-card/BlogCard";
import Masonry from "react-masonry-css";

interface Props {
  blogs: BlogList[];
}

const BlogGridView: React.FC<Props> = ({ blogs }: Props) => {
  const breakpointColumnsObj = {
    default: 3,
    900: 2,
    600: 1,
  };

  return (
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className={styles.masonry}
      columnClassName={styles.masonryColumn}
    >
      {blogs.map((blog, index) => (
        <BlogCard key={index} blog={blog} />
      ))}
    </Masonry>
  );
};

export default BlogGridView;
