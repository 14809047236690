import React from "react";
import cx from "classnames";
import { Link } from "gatsby";
import styles from "./ArrowButton.module.scss";
import { IconSizes } from "../../../enums/icon-sizes";
import SVGContainer from "../svg-container/SVGContainer";
import RightArrowIcon from "../../../images/icons/arrow-right-curve.inline.svg";

interface Props {
  text: string;
  className?: string;
  route?: string;
  iconSize?: IconSizes;
  dataTestE2E?: string;
}

const RightArrowButton: React.FC<Props> = (props: Props) => {
  const { route = "/" } = props;

  return (
    <Link
      to={route}
      className={cx(styles.arrowButton, "d7", `${props.className ?? ""}`)}
      data-test-e2e={props.dataTestE2E}
    >
      <p>{props.text}</p>
      <SVGContainer
        iconSize={props.iconSize ?? IconSizes.IC__default}
        className={styles.icon}
      >
        <RightArrowIcon />
      </SVGContainer>
    </Link>
  );
};

export default RightArrowButton;
