import React from "react";
import { Link } from "gatsby";
import cx from "classnames";
import styles from "./ArrowButton.module.scss";
import LeftArrowIcon from "../../../images/icons/arrow-left-curve.inline.svg";
import SVGContainer from "../svg-container/SVGContainer";
import { IconSizes } from "../../../enums/icon-sizes";

interface Props {
  text: string;
  className?: string;
  route?: string;
  dataTestE2E?: string;
}

const LeftArrowButton: React.FC<Props> = (props: Props) => {
  const { route = "/" } = props;

  return (
    <Link
      to={route}
      className={cx(styles.arrowButton, `${props.className ?? ""}`, "d7")}
      data-test-e2e={props.dataTestE2E}
    >
      <SVGContainer className={styles.icon} iconSize={IconSizes.IC__default}>
        <LeftArrowIcon />
      </SVGContainer>
      <p>{props.text}</p>
    </Link>
  );
};

export default LeftArrowButton;
