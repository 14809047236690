import React from "react";
import cx from "classnames";
import styles from "./blogCategory.module.scss";
import { Link } from "gatsby";
import {
  getBlogCategoryLink,
  getBlogSubCategoryLink,
} from "../../../utils/link.helper";
import BlogGridView from "../blog-grid-view/BlogGridView";
import blogCategorySEO from "../../../../content/seo/blogs-categories.json";
import { GqlListResponse } from "../../../models/gql-list-response.model";
import { BlogList } from "../../../models/blog-list.model";
import SEO from "../seo";

const BlogCategory = (props: any) => {
  const _catKey = props.pageContext.current_category;
  let _subCatKey =
    props.pageContext.current_category_slug ==
    props.pageContext.current_subcategory_slug
      ? ""
      : props.pageContext.current_subcategory_slug;
  _subCatKey = _subCatKey.substr(12);
  const seoKey = `${_catKey}__${_subCatKey}`;

  const seoData = blogCategorySEO[seoKey] || {};

  const currentCategory = props.pageContext.current_category;
  const currentSubCategory = props.pageContext.current_subcategory;

  const blogsRawData: GqlListResponse<BlogList> = props.data.blogs;
  const blogs = blogsRawData.edges.map(b => b.node);

  const portfolios = props.pageContext.portfolios.map(t => t.node);

  return (
    <>
      <SEO
        title={seoData.title || currentCategory}
        description={seoData.description}
        keywords={seoData.keywords || []}
        imageUrl={undefined}
        pageUrl={getBlogCategoryLink(currentCategory)}
      />
      <main
        className={cx("column", styles.mainContent)}
        data-test-e2e={`${_catKey}-container`}
      >
        <section className={styles.linkRow}>
          <p className={styles.linkRow__space} />
          <Link
            key="all"
            className={
              !currentSubCategory
                ? cx(styles.linkRow__selected, "d6")
                : cx("d8", styles.linkRow__notSelected)
            }
            to={getBlogCategoryLink(currentCategory)}
          >
            All
          </Link>
          {portfolios.map(({ frontmatter: { title, slug } }) => (
            <Link
              key={slug}
              className={
                slug === currentSubCategory
                  ? cx(styles.linkRow__selected, "d6")
                  : cx("d8", styles.linkRow__notSelected)
              }
              to={getBlogSubCategoryLink(currentCategory, slug)}
            >
              {title}
            </Link>
          ))}
          <p className={styles.linkRow__space} />
        </section>
        <section className="column">
          <BlogGridView blogs={blogs} />
        </section>
      </main>
    </>
  );
};

export default BlogCategory;
