import React from "react";
import styles from "./LabelDateImage.module.scss";
import cx from "classnames";
import { DateUtil } from "../../../utils/date.util";
import { isSafari } from "react-device-detect";

interface Props {
  image: string;
  subText: string;
  date: string;
  label: string;
  dateRowClassName?: string;
  paraClassName?: string;
  dateClassName?: string;
  className?: string;
}

const LabelDateImage: React.FC<Props> = (props: Props) => {
  return (
    <article
      className={cx(
        styles.container,
        isSafari && styles.safari,
        props.className ?? ""
      )}
    >
      <div className={styles.container__content}>
        <img src={props.image} alt={props.label} />
        <div className={cx(styles.dateRow, props.dateRowClassName ?? "d10")}>
          <p>{props.subText}</p>
          <p className={props.dateClassName ?? styles.dateRow__date}>
            {DateUtil.formatDate(props.date)}
          </p>
        </div>
        <p
          className={cx(
            "open",
            styles.container__content__mainText,
            props.paraClassName ?? "p2"
          )}
        >
          {props.label}
        </p>
      </div>
    </article>
  );
};

export default LabelDateImage;
