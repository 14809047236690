import { BlogCategory } from "../enums/blog-category.enum";

export class StringUtil {
  static formatBlogCategoryTitle(category: BlogCategory): string {
    return (
      {
        [BlogCategory.STORIES]: "Stories",
        [BlogCategory.PROMOTIONS]: "Promotions",
        [BlogCategory.MY_SPACE]: "My Space",
        [BlogCategory.TESTIMONIALS]: "Testimonials",
      }[category] || ""
    );
  }

  static getFileNameAsURL(url: string): string {
    const fileNameWithExt = new URL(url).pathname.split("/").pop() || "";
    const fileName = fileNameWithExt.split(".").slice(0, -1).join(".");

    return fileName.replace(/^(?:[0-9]{1,3}-)(?:[0-9]{1,3}-)(.*)$/, "$1"); // Removing serial number
  }

  static getFileNameFromURL(url: string): string {
    const fileNameWithExt = new URL(url).pathname.split("/").pop() || "";
    const fileName = fileNameWithExt.split(".").slice(0, -1).join(".");

    return fileName
      .replace(/^(?:[0-9]{1,3}-)(?:[0-9]{1,3}-)(.*)$/, "$1") // Removing serial number
      .replace(/[\-_]/g, " ") // Replacing - and _ with nb
      .split(" ")
      .map(w => w.charAt(0).toUpperCase().concat(w.slice(1, w.length)))
      .join(" ");
  }
}
