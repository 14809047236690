import React from "react";
import { graphql } from "gatsby";
import BlogCategory1 from "../components/v1/templates/blogCategory";
import BlogCategory2 from "../components/v2/templates/blogCategoryTemplate";

const BlogCategoryTemplate = (props: any) => {
  if (process.env.GATSBY_VERSION === "v1") {
    return <BlogCategory1 {...props} />;
  } else {
    return <BlogCategory2 {...props} />;
  }
};

export const pageQuery = graphql`
  query($current_category_slug: String!, $current_subcategory_slug: String!) {
    blogs: allGhostPost(
      filter: {
        tags: {
          elemMatch: {
            slug: {
              eq: $current_category_slug
              in: [$current_subcategory_slug]
            }
          }
        }
      }
    ) {
      edges {
        node {
          slug
          id
          uuid
          title
          comment_id
          feature_image
          featured
          visibility
          published_at
          reading_time
          tags {
            id
            slug
            name
          }
        }
      }
    }
  }
`;

export default BlogCategoryTemplate;
