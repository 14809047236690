import React from "react";
import styles from "./blogCategory.module.scss";
import cx from "classnames";

import BlogLayout from "../../blog-layout/BlogLayout";
import BlogHeader from "../../blog-header/BlogHeader";
import LeftArrowButton from "../../../commons/curve-arrow-button/LeftArrowButton";
import RightArrowButton from "../../../commons/curve-arrow-button/RightArrowButton";
import { StringUtil } from "../../../../utils/string.util";
import { getBlogCategoryLink } from "../../../../utils/link.helper";
import { RouteLinks } from "../../../../utils/route-links";
import BlogCategoryComponent from "../../../commons/blog-category";

const BlogCategory = (props: any) => {
  const currentCategory = props.pageContext.current_category;

  return (
    <BlogLayout>
      <BlogHeader>
        <div className={styles.header}>
          <div className={styles.header__row}>
            <LeftArrowButton
              text="All articles"
              className={styles.header__btn}
              route={RouteLinks.blogsAll}
              dataTestE2E="blogs-all"
            />
            <h1 className={cx(styles.header__heading, "blog-d3")}>
              {StringUtil.formatBlogCategoryTitle(currentCategory)}
            </h1>
            <RightArrowButton
              text={StringUtil.formatBlogCategoryTitle(
                props.pageContext.next_category
              )}
              route={getBlogCategoryLink(props.pageContext.next_category)}
              dataTestE2E={props.pageContext.next_category}
            />
          </div>
          <h2 className={cx(styles.header__smallScreen, "blog-d3")}>
            {StringUtil.formatBlogCategoryTitle(currentCategory)}
          </h2>
        </div>
      </BlogHeader>
      <BlogCategoryComponent {...props} />
    </BlogLayout>
  );
};

export default BlogCategory;
