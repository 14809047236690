import React from "react";
import LabelDateImage from "../../label-date-image/LabelDateImage";
import { BlogList } from "../../../../models/blog-list.model";
import { Link } from "gatsby";
import { getBlogLink } from "../../../../utils/link.helper";
import styles from "./BlogCard.module.scss";
import { removeBlogCategoryTags } from "../../../../utils/utils";

interface Props {
  blog: BlogList;
  dateRowClassName?: string;
  paraClassName?: string;
  dateClassName?: string;
  dataTestE2E?: string;
  cardClassName?: string;
}

const BlogCard: React.FC<Props> = (props: Props) => {
  const { blog, ...forwardProps } = props;

  const blogTags = removeBlogCategoryTags(blog);

  const _firstTag = (blogTags || [])[0];

  return (
    <Link
      to={getBlogLink(blog.slug)}
      className={styles.BlogCard}
      data-test-e2e={props.dataTestE2E}
    >
      <LabelDateImage
        image={blog.feature_image!}
        subText={_firstTag?.name || ""}
        date={blog.published_at}
        label={blog.title}
        className={props.cardClassName}
        {...forwardProps}
      />
    </Link>
  );
};

export default BlogCard;
